<script>
import { defineComponent } from 'vue'

import SqDialog from 'components/Common/SqDialog.vue'
import JsonEditor from "components/JsonEditor.vue";

export default defineComponent({
  name: 'saveQueryModal',
  components: {
    JsonEditor,
    SqDialog
  },
  props: {
    query: {
      required: true,
      type: String
    }
  },
  emits: ['confirm'],
  data () {
    return {
      name: '',
      queryModel: this.$props.query,
      showOverwriteWarning: false
    }
  },
  computed: {
    isInStorage() {
      let index = -1;
      try {
        index = JSON.parse(localStorage.getItem("user")).user_preference?.queries.findIndex(query => query.name === this.name);
      } catch (e) {}
      this.showOverwriteWarning = index !== -1;
      return this.showOverwriteWarning;
    }
  },
  methods: {
    confirmSave() {
      this.$emit('confirm', this.name, this.queryModel);
    }
  },
})
</script>

<template>
  <sq-dialog
      type="create"
      @save="confirmSave"
      :save-button-label="$t('general.save')"
  >
    <template #title>
      {{ $t('entities.modal.saveQueryTitle') }}
    </template>

    <template #content>
      {{ $t('entities.modal.saveQueryLabel') }}
      <q-input required dense autofocus
               v-model="name"
               aria-autocomplete="none"
               class="q-mt-xs q-mb-md"
               :label="$t('entities.modal.name')"
               :title="$t('entities.modal.name')"
      />

      <json-editor
        v-model:modelValue="queryModel"
        max-height="none"
        :show-toolbar="false"
      />

      <div v-if="isInStorage" class="q-mt-md">
        <q-icon name="warning" color="warning" size="sm" /> {{ $t('entities.modal.saveQueryOverwriteWarning') }}
      </div>

    </template>

    <template #saveButton>
      <q-btn flat
             type="submit"
             color="primary"
             :disabled="!name"
             :label="this.$t('general.save')"
             :class="{ 'disabled': !name }"
             @click="confirmSave"
      />
    </template>
  </sq-dialog>
</template>
